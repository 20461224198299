<template>
    <div class="container-xl" v-if="!$store.getters.isForbidden">
      <div class="card col-lg-12">
        <div class="card-header justify-content-between">
          <div class="card-title">{{ $route.name }} List</div>
          <div class="flex flex-row float-right">
            <el-button v-if="currentRole.can_create" type="primary" @click="doShowModalCreate" size="small"><font-awesome-icon icon="plus"/> Create new Promo</el-button>
          </div>
        </div>
        <div class="card-body">
          <div class="flex">
            <div class="w-1/3">
              <el-form ref="form2" label-width="150px">
                <el-form-item label="Review Status">
                  <el-select size="small" v-model="review_status_filter" placeholder="Review status" class="mr-2">
                    <el-option label="All" value=""/>
                    <el-option label="Approved" value="approved"/>
                    <el-option label="In Review" value="in_review"/>
                  </el-select>
                </el-form-item>
                <el-form-item label="Promo Status">
                  <el-select size="small" v-model="status_filter" placeholder="Promo status" class="mr-2">
                    <el-option label="All" value=""/>
                    <el-option label="Active" value="1"/>
                    <el-option label="Inactive" value="0"/>
                    <el-option label="Disabled" value="2"/>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
            <div class="w-2/3">
              <el-form ref="form2" label-width="150px">
                <el-form-item label="Promo Effective Date">
                  <el-date-picker
                    v-model="search_date"
                    type="daterange"
                    size="small"
                    class="w-50"
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date">
                  </el-date-picker>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <el-button type="info" @click="searchHandler" size="small"><font-awesome-icon icon="search"/> Search</el-button>
        </div>
        <div class="table-responsive">
          <table class="table card-table text-nowrap table-vcenter">
            <thead>
              <tr>
                <th width="30%">Promo Name</th>
                <th width="20%">Promo Method</th>
                <th width="20%">Promo Type</th>
                <th>Promo Code</th>
                <th>Discount Type</th>
                <th>Discount Amount</th>
                <th>Start From</th>
                <th>End At</th>
                <th>Status</th>
                <th>Review Status</th>
                <th>#</th>
                <!-- <th>Price</th> -->
                <!-- <th width="10%">Base Price</th> -->
              </tr>
            </thead>
            <tbody v-loading="isLoading">
              <tr v-for="(item, i) in list" :key="i">
                <td>{{ truncate(item.promo_name, { length: 48 }) }}</td>
                <td>{{ item.promo_type_str }}</td>
                <td v-if="item.is_mybenefit">{{ item.is_mybenefit ? 'Company Voucher' : 'User Selected' }}</td>
                <td v-else>All</td>
                <td>{{ item.config.voucher_code }}</td>
                <td>{{ item.discount_type_str }}</td>
                <td>{{ item.discount_amount_str }}</td>
                <td class="text-nowrap text-muted">{{ item.promo_start_at_str }}</td>
                <td class="text-nowrap text-muted">{{ item.promo_end_at_str }}</td>
                <td><span class="tag" :class="item.status_color_str">{{ item.status_str }}</span></td>
                <td>
                  <span v-if="item.review_status == 'rejected'" class="tag" :class="item.review_status_color">{{ item.review_status_str }}</span>
                  <span v-else class="tag" :class="item.review_status_color">{{ item.review_status_str }}</span>
                </td>
                <td v-if="item.promo_status !== 2">
                  <button :disabled="!currentRole.can_edit && !currentRole.can_param1" class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown">Actions</button>
                  <div v-if="currentRole.can_edit || currentRole.can_param1" class="dropdown-menu dropdown-menu-right dropdown-menu-arrow float-right p-2" style="width: 200px">
                    <a v-if="currentRole.can_edit" @click="doShowModalEdit(item, 'edit')" title="Edit promo" class="dropdown-item btn-sm cursor-pointer"><font-awesome-icon class="text-primary" icon="pen"/>&nbsp; Edit Promo</a>
                    <a v-if="currentRole.can_edit" @click="clickDuplicatePromo(item)" title="Create promo" class="dropdown-item btn-sm cursor-pointer"><font-awesome-icon class="text-primary" icon="copy"/>&nbsp; Duplicate Promo</a>
                    <a v-if="currentRole.can_param1" @click="doShowModalEdit(item, 'review')" title="Edit promo" class="dropdown-item btn-sm cursor-pointer"><font-awesome-icon class="text-brand" icon="file-alt"/>&nbsp; Review Promo</a>
                    <a v-if="currentRole.can_delete" @click="confirmDeletePromo(item._id)" title="Disabled promo" class="dropdown-item btn-sm cursor-pointer"><font-awesome-icon class="text-danger" icon="trash"/>&nbsp; Disabled Promo</a>
                    <a v-if="currentRole.can_edit" @click="enableDisablePromo(item)" title="Disabled promo" class="dropdown-item btn-sm cursor-pointer"><font-awesome-icon class="text-warning" icon="power-off"/>&nbsp; Publish/Unpublish Promo</a>
                  </div>
                </td>
                <td v-if="item.promo_status === 2">
                  <button class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown">Actions</button>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow float-right p-2" style="width: 200px">
                    <a @click="doShowPromo(item, 'show')" title="Show promo" class="dropdown-item btn-sm cursor-pointer"><font-awesome-icon class="text-primary" icon="eye"/>&nbsp; Show Promo</a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
        </div>
        <div class="card-footer d-flex justify-content-between">
          <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
          <div class="inline-table">
            <b-pagination
              class="table-cell"
              @change="pageChangeHandler"
              :per-page="per_page"
              :limit="5"
              :total-rows="total_rows"
              v-model="page"
            />
          </div>
        </div>
        <b-modal size="lg" v-model="showModalCreate" :title="modalTitle" hide-footer>
          <b-form @submit.prevent="createNewPromo" @reset="closeModalCreate">
            <b-form-group label="Promo Name">
              <b-input
                v-model="v$.form.name.$model"
                placeholder="Input promo name"
                type="text"/>
              <b-form-invalid-feedback :state="!v$.form.name.$invalid" v-if="v$.form.name.$errors[0]">
                <span> {{ v$.form.name.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Promo Code">
              <b-input
                v-model="v$.form.voucher_code.$model"
                oninput="this.value = this.value.toUpperCase()"
                placeholder="Input voucher code"
                type="text"/>
              <b-form-invalid-feedback :state="!v$.form.voucher_code.$invalid" v-if="v$.form.voucher_code.$errors[0]">
                <span> {{ v$.form.voucher_code.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Description">
              <quill-editor v-model="form.promo_description"
                ref="promoDescriptionQuill"
                :options="editorOption">
              </quill-editor>
            </b-form-group>
            <b-form-group label="Banner">
              <b-form-file
                v-model="selected_file.promo_banner"
                :state="Boolean(selected_file.promo_banner)"
                class="mt-2 border-1 p-2"
                accept=".png, .jpg, .jpeg"
                @input="onChangeFile($event, 'promo_banner')"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
              <img ref="current_promo_banner" :src="form.current_promo_banner"/>
            </b-form-group>
            <b-form-group label="Logo">
              <b-form-file
                v-model="selected_file.promo_image_url"
                :state="Boolean(selected_file.promo_image_url)"
                class="mt-2 border-1 p-2"
                accept=".png, .jpg, .jpeg"
                @input="onChangeFile($event, 'promo_image_url')"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
              <img ref="current_promo_image_url" :src="form.current_promo_image_url"/>
            </b-form-group>
            <b-form-group label="Promo Type">
              <el-select
                class="w-full"
                v-model="v$.form.type.$model"
                placeholder="Select promo type">
                <el-option v-for="(item, index) in promoTypeOptions" :value="item.value" :key="index" :label="item.text"/>
              </el-select>
              <b-form-invalid-feedback :state="!v$.form.type.$invalid" v-if="v$.form.type.$errors[0]">
                <span> {{ v$.form.type.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Product Type">
              <el-select
                multiple
                class="w-full"
                v-model="form.promo_product_type"
                placeholder="Select product type">
                <el-option v-for="(item, index) in productTypeOptions" :value="item.value" :key="index" :label="item.label"/>
              </el-select>
              <small class="text-warning"><i>*Leave blank if this promo apply to all product</i></small>
            </b-form-group>
            <b-form-group label="Set as Exclusive Promo">
              <el-switch
                :active-value="true"
                :inactive-value="false"
                active-text="Yes"
                inactive-text="No"
                v-model="form.is_exclusive_promo"
                active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch>
            </b-form-group>
            <b-form-group label="Promo Start Date">
              <el-date-picker
                :picker-options="datePickerOptions"
                class="w-full"
                v-model="v$.form.start_date.$model"
                type="date"
                placeholder="Pick start date"/>
                <!-- {{ v$.form.start_date }} -->
              <b-form-invalid-feedback :state="!v$.form.start_date.$invalid" v-if="v$.form.start_date.$error">
                <span v-if="v$.form.start_date.$errors[0].$validator !== 'minValue'"> {{ v$.form.start_date.$errors[0].$message }}</span>
                <span v-else> Value must below {{ moment(v$.form.end_date.$model).format('YYYY-MM-DD') }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Promo End Date">
              <el-date-picker
                :picker-options="datePickerOptions"
                class="w-full"
                v-model="v$.form.end_date.$model"
                type="date"
                placeholder="Pick start date"/>
              <b-form-invalid-feedback :state="!v$.form.end_date.$invalid" v-if="v$.form.end_date.$error">
                <span v-if="v$.form.end_date.$errors[0].$validator !== 'minValue'"> {{ v$.form.end_date.$errors[0].$message }}</span>
                <span v-else> Value must below {{ moment(v$.form.start_date.$model).format('YYYY-MM-DD') }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Discount Type">
              <el-select
                class="w-full"
                v-model="v$.form.discount_type.$model"
                placeholder="Select discount type">
                <el-option v-for="(item, index) in discountTypeOptions" :value="item.value" :key="index" :label="item.text"/>
              </el-select>
              <b-form-invalid-feedback :state="!v$.form.discount_type.$invalid" v-if="v$.form.discount_type.$errors[0]">
                <span> {{ v$.form.discount_type.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Minimum Amount Transaction">
              <b-input
                v-model="v$.form.minimum_amount_transaction.$model"
                placeholder="Input minimum amount per transaction"
                type="number"/>
              <b-form-invalid-feedback :state="!v$.form.minimum_amount_transaction.$invalid" v-if="v$.form.minimum_amount_transaction.$errors[0]">
                <span> {{ v$.form.minimum_amount_transaction.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Discount Amount">
              <b-input
                v-model="v$.form.discount_amount.$model"
                placeholder="Input discount amount"
                type="number"/>
              <b-form-invalid-feedback :state="!v$.form.discount_amount.$invalid" v-if="v$.form.discount_amount.$errors[0]">
                <span> {{ v$.form.discount_amount.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Max. Discount Amount">
              <b-input
                v-model="v$.form.discount_amount_max.$model"
                placeholder="Input maximal discount amount per transaction"
                type="number"/>
              <b-form-invalid-feedback :state="!v$.form.discount_amount_max.$invalid" v-if="v$.form.discount_amount_max.$errors[0]">
                <span> {{ v$.form.discount_amount_max.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Limit usage per user">
              <b-input
                v-model="v$.form.max_use.$model"
                placeholder="Input limitation for promo"
                type="number"/>
              <b-form-invalid-feedback :state="!v$.form.max_use.$invalid" v-if="v$.form.max_use.$errors[0]">
                <span> {{ v$.form.max_use.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <div class="d-flex flex-row justify-content-between">
              <div class="float-left" v-if="currentRole.can_param1 && modal_action == 'review'">
                <el-button @click="confirmApproveReject('approve')" size="small" type="success" class="mr-2">Approve</el-button>
                <el-button @click="confirmApproveReject('reject')" size="small" type="danger" class="mr-2">Reject</el-button>
              </div>
              <div class="float-right" v-if="currentRole.can_edit">
                <el-button @click="confirmCreatePromo" :loading="loading.create_promo" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
                <el-button @click="closeModalCreatePromo" size="small" class="mr-2">Cancel</el-button>
              </div>
              <!-- <b-button class="mr-2" size="sm" @click="closeModalGiveVoucher" type="reset" variant="secondary">Cancel</b-button> -->
            </div>
          </b-form>
        </b-modal>
      </div>
    </div>
</template>
<script>
import { truncate } from 'lodash';
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { quillEditor } from 'vue-quill-editor';
// require styles
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import {
  required, maxValue, minValue, requiredIf,
} from '@vuelidate/validators';
import {
  GET_PROMOS, CREATE_PROMO, UPDATE_PROMO, DELETE_PROMO, APPROVE_REJECT_PROMO, ENABLE_DISABLE_PROMO,
} from '@/store/modules/promo';
import emptyStateImage from '@/assets/images/empty-state.png';
import popupErrorMessages from '@/library/popup-error-messages';
import base64File from '@/library/base64File';
import imageCompress from '@/library/vue-image-compression';

export default {
  name: 'Promo',
  components: {
    quillEditor,
  },
  metaInfo: {
    title: 'Promo',
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      truncate,
      moment,
      loading: {
        create_promo: false,
      },
      showModalCreate: false,
      emptyStateImage,
      merchantList: [],
      merchant_id: '',
      filter_merchant_loading: false,
      per_page: 20,
      total_rows: 0,
      page: 1,
      list: [],
      isLoading: true,
      loader: null,
      search_keyword: '',
      search_by: '',
      search_merchant_keyword: '',
      search_date: [],
      modalType: 'add',
      form: {
        name: '',
        type: 'voucher_code',
        discount_type: 'percent',
        voucher_code: '',
        discount_amount: '',
        max_use: '',
        discount_amount_max: '',
        minimum_amount_transaction: '',
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().add(7, 'days').format('YYYY-MM-DD'),
        promo_description: '',
        promo_banner: '',
        promo_image_url: '',
        is_exclusive_promo: false,
        promo_product_type: [],
      },
      editorOption: {
        placeholder: 'Please input promo description',
      },
      promoTypeOptions: [
        {
          value: 'voucher_code',
          text: 'Voucher Code',
        },
        {
          value: 'admin_fee',
          text: 'Admin Fee',
        },
      ],
      discountTypeOptions: [
        {
          value: 'percent',
          text: 'Percentage',
        },
        {
          value: 'fixed',
          text: 'Fixed Amount',
        },
      ],
      datePickerOptions: {
        disabledDate: (date) => moment(date).isBefore(moment(), 'days'),
      },
      selected_file: {
        promo_banner: null,
        promo_banner_base64: '',
        promo_image_url: null,
        promo_image_url_base64: '',
      },
      review_status_filter: '',
      status_filter: '',
      modal_action: 'add',
      selected_promo: {},
      productTypeOptions: [
        {
          value: 'voucher',
          label: 'Voucher',
        },
        {
          value: 'pln',
          label: 'PLN',
        },
        {
          value: 'pulsa',
          label: 'Pulsa',
        },
        {
          value: 'lms',
          label: 'Academy',
        },
        {
          value: 'insurance',
          label: 'Insurance',
        },
      ],
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        voucher_code: {
          required,
        },
        type: {
          required,
        },
        discount_type: {
          required,
        },
        discount_amount: {
          required,
          maxValue: maxValue(this.form.discount_type === 'percent' ? 100 : 1000000),
        },
        start_date: {
          required,
          minValue: (val) => moment(val).isSameOrBefore(this.form.end_date),
        },
        end_date: {
          required,
          minValue: (val) => moment(val).isSameOrAfter(this.form.start_date),
        },
        max_use: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(100),
        },
        minimum_amount_transaction: { required },
        discount_amount_max: { required: requiredIf(this.form.discount_type === 'percent') },
      },
    };
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
    modalTitle() {
      return this.modalType === 'add' ? 'Create New Promo' : 'Edit Promo';
    },
    currentRole() {
      return this.$store.getters.current_role;
    },
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getListPromo();
    loader.hide();
  },
  methods: {
    async onChangeFile(event, target) {
      if (this.selected_file[target]) {
        const compressed = await imageCompress(this.selected_file[target]);
        this.selected_file[`${target}_base64`] = await base64File(compressed);
        try {
          this.$refs[`current_${target}`].src = this.selected_file[`${target}_base64`];
          this.form[`current_${target}`] = this.selected_file[`${target}_base64`];
          this.form[target] = this.form[`current_${target}`];
        } catch (error) {
          console.log(error);
        }
      }
    },
    pageChangeHandler(page) {
      this.page = page;
      this.getListPromo();
    },
    searchHandler() {
      this.page = 1;
      this.getListPromo();
    },
    async getListPromo() {
      this.isLoading = true;
      if (this.status_filter) {
        this.search_by = 'promo_status';
        this.search_keyword = this.status_filter;
      }
      const opt = {
        page: this.page,
        per_page: this.per_page,
        search_by: this.search_by,
        search_keyword: this.search_keyword,
        review_status_filter: this.review_status_filter,
      };
      if (this.search_date && this.search_date.length) {
        opt.start_date = moment(this.search_date[0]).format('YYYY-MM-DD');
        opt.end_date = moment(this.search_date[1]).format('YYYY-MM-DD');
      }
      await this.$store.dispatch(GET_PROMOS, opt).catch(() => {});
      const { rows, count } = this.$store.getters.promos;
      this.total_rows = count || 0;
      this.list = rows;
      this.isLoading = false;
      this.search_by = null;
      this.search_keyword = null;
    },
    doShowModalCreate() {
      this.$router.push({
        name: 'Create Promo',
        params: {
          action: 'add',
        },
      });
    },
    doShowModalEdit(item, action) {
      if (item.review_status !== 'in_review' && action === 'review') {
        this.$message({
          title: 'Oops',
          type: 'warning',
          message: 'This promo is not waiting for review',
        });
        return;
      }
      this.$router.push({
        name: 'Edit Promo',
        params: {
          id: item._id,
          action,
        },
      });
    },
    doShowPromo(item, action) {
      this.$router.push({
        name: 'Show Promo',
        params: {
          id: item._id,
          action,
        },
      });
    },
    closeModalCreate() {
      this.showModalCreate = false;
    },
    confirmCreatePromo() {
      if (this.modalType === 'edit') {
        this.doUpdatePromo();
        return;
      }
      this.$confirm('You will create a new promo. Make sure you have input data correctly. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.doCreatePromo();
          }
          done();
        },
      }).catch(() => {});
    },
    async doUpdatePromo() {
      this.form.minimum_amount_transaction = parseInt(this.form.minimum_amount_transaction, 0);
      this.form.discount_amount_max = parseInt(this.form.discount_amount_max, 0);
      this.form.max_use = parseInt(this.form.max_use, 0);
      this.form.discount_amount = parseFloat(this.form.discount_amount);
      this.$confirm('You will update this promo. Make sure you have input data correctly. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            this.loading.create_promo = true;
            await this.$store.dispatch(UPDATE_PROMO, this.form)
              .then(() => {
                this.$message({
                  title: 'Success',
                  type: 'success',
                  message: 'Promo updated',
                });
                this.showModalCreate = false;
                this.getListPromo();
              }).catch((err) => {
                popupErrorMessages(err.response.data);
                this.loading.create_promo = false;
              });
            this.loading.create_promo = false;
            return;
          }
          done();
        },
      }).catch(() => {});
    },
    async confirmDeletePromo(id) {
      this.$confirm('You will disable this promo. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(DELETE_PROMO, id)
              .then(() => {
                this.$message({
                  title: 'Success',
                  type: 'success',
                  message: 'Promo disa',
                });
                this.getListPromo();
              }).catch((err) => {
                popupErrorMessages(err.response.data);
              });
            instance.confirmButtonLoading = false;
            return;
          }
          done();
        },
      }).catch(() => {});
    },
    async enableDisablePromo(item) {
      if (item.review_status !== 'approved' && item.promo_status === 0) {
        this.$message({
          title: 'Oops',
          type: 'error',
          message: 'You can not publish this promotion because it is not approved',
        });
        return;
      }
      let msg = 'You will unpublish this promo. Continue?';
      if (item.promo_status === 0) {
        msg = 'You will publish this promo. Continue?';
      }
      this.$confirm(msg, 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(ENABLE_DISABLE_PROMO, {
              id: item._id,
              data: {
                promo_status: item.promo_status === 1 ? 0 : 1,
              },
            })
              .then(() => {
                this.$message({
                  title: 'Success',
                  type: 'success',
                  message: 'Promo updated',
                });
                this.getListPromo();
              }).catch((err) => {
                popupErrorMessages(err.response.data);
              });
            instance.confirmButtonLoading = false;
            return;
          }
          done();
        },
      }).catch(() => {});
    },
    async doCreatePromo() {
      this.loading.create_promo = true;
      await this.$store.dispatch(CREATE_PROMO, this.form)
        .then(() => {
          this.$message({
            title: 'Success',
            type: 'success',
            message: 'Promo created',
          });
          this.showModalCreate = false;
          this.getListPromo();
        }).catch((err) => {
          popupErrorMessages(err.response.data);
        });
      this.loading.create_promo = false;
    },
    closeModalCreatePromo() {
      this.form = {
        name: '',
        type: 'voucher_code',
        discount_type: 'percent',
        voucher_code: '',
        discount_amount: 0,
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().add(7, 'days').format('YYYY-MM-DD'),
      };
      this.showModalCreate = false;
    },
    confirmApproveReject(confirm_action) {
      let msg = `You will approve this promo (${this.selected_promo.promo_name}). Continue?`;
      if (confirm_action === 'reject') {
        msg = `You will reject this promo (${this.selected_promo.promo_name}). Please input a reason why you reject this promotion.`;
      }
      this.closeModalCreatePromo();
      this.$confirm(msg, 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(APPROVE_REJECT_PROMO, {
              id: this.selected_promo._id,
              data: {
                review_status: confirm_action === 'reject' ? 'rejected' : 'approved',
                reject_reason: confirm_action === 'reject' ? this.form.reject_reason : null,
              },
            }).catch(() => {});
            this.$message({
              title: 'Success',
              type: 'success',
              message: 'Review success',
            });
            instance.confirmButtonLoading = false;
            this.getListPromo();
          }
          done();
        },
      }).catch(() => {
        this.doShowModalEdit(this.selected_promo, 'review');
      });
    },
    clickDuplicatePromo(item) {
      this.$router.push({
        name: 'Create Promo',
        params: {
          action: 'add',
        },
        query: {
          dup_source: item._id,
        },
      });
    },
  },
};
</script>
<style>
.el-tooltip__popper {
  max-width: 250px;
}
</style>
